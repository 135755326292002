import React, { useEffect, useState } from "react";
import { tokenConfig } from "client/actions/apiActions";
import logo from "../../../assets/bh-logo.png";
import Loading from "../../layout/Loading";
import axios from "axios";
import "../../../assets/Menu.css";
import { ApiURL } from "../../../config";

const FoodMenu = () => {
  const [menu, setMenu] = useState({});
  const [loading, setLoading] = useState(true);

  const loadMenu = async () => {
    const res = await axios.get(`${ApiURL}/getMenu`, tokenConfig());
    console.log("res", res.data.menu);
    setMenu(res.data.menu);
    setLoading(false);
  };
  useEffect(() => {
    loadMenu();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container my-4 bg-white rounded">
      <div className="row">
        <div className="col-12 text-center">
          {logo && (
            <img
              src={logo}
              className="img-fluid"
              alt="Menu"
              style={{ maxWidth: "300px" }}
            />
          )}
          <h1 className={"text-center"}>{menu.name}</h1>
          {menu?.image && (
            <>
              <img src={`${menu.image}`} className="img-fluid" alt={"Menu"} />
              <br />
            </>
          )}
        </div>
        <div className="col-12 mt-4">
          <div dangerouslySetInnerHTML={{ __html: menu?.description }} />
        </div>

        {menu?.categories?.map((category, index) => {
          return (
            <>
              <div className="col-12">
                <h2 className="h2 mt-4 border-bottom text-center">
                  {category.name}
                </h2>
                <div className="text-center">{category.description}</div>
              </div>
              <MenuCategories key={index} category={category} />
            </>
          );
        })}
        <div className="col-12 mt-4 mb-4">
          <hr />
        </div>
        <div className="col-12 mt-4 mb-4">{menu?.disclaimer}</div>
      </div>
    </div>
  );
};
export default FoodMenu;

const MenuCategories = ({ category }) => {
  return (
    <>
      <div className="col-12">
        {category.subTitle !== "" &&
          typeof category.subTitle !== "undefined" && (
            <h3 className="h3 mt-4 border-bottom">{category.subTitle}</h3>
          )}
      </div>
      <div className="MenuColumn mt-4">
        {category?.items?.map((item, index) => {
          return <MenuItem key={index} item={item} />;
        })}
      </div>
    </>
  );
};

const MenuItem = ({ item }) => {
  if (typeof item.price2Description === "undefined") {
    item.price2Description = "";
  }
  if (typeof item.price3Description === "undefined") {
    item.price3Description = "";
  }
  if (typeof item.price4Description === "undefined") {
    item.price4Description = "";
  }
  if (typeof item.price5Description === "undefined") {
    item.price5Description = "";
  }
  let menuItemContent = "";
  if (item.image !== "") {
    menuItemContent +=
      '<div class="col-12 text-center"><img src="' +
      item.image +
      '" class="img-fluid" alt="" /></div><br />';
  }
  menuItemContent += '<b class="text-capitalize">' + item.name + "</b>";
  if (item.favorite === 1 || item.favorite === "1") {
    menuItemContent += ' - <span class="badge text-bg-danger">FAV</span>';
  }
  if (item.description !== "") {
    menuItemContent += "<br/>";
  }
  menuItemContent += item.description;

  if (item.priceOnRows === 1 || item.priceOnRows === "1") {
    if (item.priceDescription !== "") {
      if (item.price === "0.00") {
        item.price = "Market Price";
      } else {
        if (item.price.includes("$") === false) {
          item.price = "$" + item.price;
        }
      }
      menuItemContent +=
        "<br /><b>" + item.priceDescription + " - " + item.price + "</b>";
    }
    if (item.price2Description !== "") {
      if (item.price2 === "0.00") {
        item.price2 = "Market Price";
      } else {
        if (item.price2.includes("$") === false) {
          item.price2 = "$" + item.price2;
        }
      }
      menuItemContent +=
        "<br /><b>" + item.price2Description + " - " + item.price2 + "</b>";
    }
    if (item.price3Description !== "") {
      if (item.price3 === "0.00") {
        item.price3 = "Market Price";
      } else {
        if (item.price3.includes("$") === false) {
          item.price3 = "$" + item.price3;
        }
      }
      menuItemContent +=
        "<br /><b>" + item.price3Description + " - " + item.price3 + "</b>";
    }
    if (item.price4Description !== "") {
      if (item.price4 === "0.00") {
        item.price4 = "Market Price";
      } else {
        if (item.price4.includes("$") === false) {
          item.price4 = "$" + item.price4;
        }
      }
      menuItemContent +=
        "<br /><b>" + item.price4Description + " - " + item.price4 + "</b>";
    }
    if (item.price5Description !== "") {
      if (item.price5 === "0.00") {
        item.price5 = "Market Price";
      } else {
        if (item.price5.includes("$") === false) {
          item.price5 = "$" + item.price5;
        }
      }
      menuItemContent +=
        "<br /><b>" + item.price5Description + " - " + item.price5 + "</b>";
    }
  } else {
    if (item.priceDescription !== "") menuItemContent += "<br/>";
    if (item.price2 === "0.00") {
      menuItemContent += "<b>" + item.priceDescription + "</b>";
      if (item.price !== "0.00") {
        menuItemContent += " <b> $" + item.price + "</b>";
      }
    } else {
      if (item.priceDescription === "") {
        menuItemContent += " - ";
      }
    }
    if (item.price2 !== "0.00") {
      menuItemContent +=
        "<b>" +
        item.priceDescription +
        " $" +
        item.price +
        "</b> | <b>" +
        item.price2Description +
        " $" +
        item.price2 +
        "</b>";
    }
    if (item.price3 !== "0.00") {
      menuItemContent +=
        " | <b>" + item.price3Description + " $" + item.price3 + "</b>";
    }
    if (item.price4 !== "0.00") {
      menuItemContent +=
        " | <b>" + item.price4Description + " $" + item.price4 + "</b>";
    }
    if (item.price5 !== "0.00") {
      menuItemContent +=
        " | <b>" + item.price5Description + " $" + item.price5 + "</b>";
    }
  }

  if (item.featured === 1 || item.featured === "1") {
    return (
      <div className={`mt-2 col-12 mb-4 MenuItem`}>
        <div className="card bg-light text-dark">
          <div
            className="card-body"
            dangerouslySetInnerHTML={{ __html: menuItemContent }}
          />
        </div>
      </div>
    );
  }
  if (item.featured !== 1 && item.featured !== "1") {
    return (
      <div className={`mt-2 col-12 mb-4 MenuItem`}>
        <div dangerouslySetInnerHTML={{ __html: menuItemContent }} />
      </div>
    );
  }
};
