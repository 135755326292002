import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getReceipt } from "client/actions/apiActions";
import logo from "../../../assets/bh-logo.png";
import "../../../assets/Receipt.css";
import { useParams } from "react-router-dom";
import Loading from "../../layout/Loading";

const Receipt = (props) => {
  const { receiptId } = useParams();
  const [orderInfo, setOrderInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.getReceipt(receiptId);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (props.orderInfo !== null && props.orderInfo !== undefined) {
      let tmp = {
        ...props.orderInfo,
        ...props.orderInfoData,
        ...props.receiptData,
      };
      setOrderInfo(tmp);
      setLoading(false);
    }
  }, [props.orderInfo]);

  if (loading) {
    return <Loading />;
  }

  return (
    <table className="body-wrap" id="Receipt">
      <tbody>
        <tr>
          <td className="container" width="600">
            <div className="content">
              <table
                className="main"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
              >
                <tbody>
                  <tr>
                    <td className="content-wrap text-center">
                      <table width="100%" cellPadding="0" cellSpacing="0">
                        <tbody>
                          <tr>
                            <td className="content-block">
                              <img src={logo} alt={orderInfo.customerName} />
                            </td>
                          </tr>
                          {orderInfo.showMenu && (
                            <tr>
                              <td className="content-block">
                                <a href={`/menu?customerId=${orderInfo.id}`}>
                                  To order food click here
                                </a>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td className="content-block">
                              <h2>Receipt</h2>
                              <h4>{orderInfo.paymentMethod}</h4>
                              <table className="invoice">
                                <tbody>
                                  <tr>
                                    <td>{orderInfo.purchaseDate}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <table
                                        className="invoice-items"
                                        cellPadding="0"
                                        cellSpacing="0"
                                      >
                                        <tbody>
                                          <tr>
                                            <td colSpan="2">
                                              Unit: {orderInfo.unitNumber}
                                            </td>
                                          </tr>
                                          {orderInfo.tipAmount !== 0 ? (
                                            <>
                                              <tr className="total">
                                                <td
                                                  colSpan="2"
                                                  className="text-end"
                                                >
                                                  Retail Value ${" "}
                                                  {orderInfo.retailPrice}
                                                </td>
                                              </tr>
                                              <tr className="total">
                                                <td
                                                  colSpan="2"
                                                  className="text-end"
                                                >
                                                  Sub Total ${" "}
                                                  {orderInfo.subtotal}
                                                </td>
                                              </tr>
                                              <tr className="total">
                                                <td colSpan="2">
                                                  Gratuity Selected{" "}
                                                  {orderInfo.tipPercent}%
                                                </td>
                                              </tr>
                                              <tr className="total">
                                                <td
                                                  colSpan="2"
                                                  className="text-end"
                                                >
                                                  Gratuity ${" "}
                                                  {orderInfo.tipAmount}
                                                </td>
                                              </tr>
                                              <tr className="total">
                                                <td
                                                  colSpan="2"
                                                  className="text-end"
                                                >
                                                  Total $ {orderInfo.total}
                                                </td>
                                              </tr>
                                            </>
                                          ) : (
                                            <>
                                              <tr className="total">
                                                <td
                                                  colSpan="2"
                                                  className="text-end"
                                                >
                                                  Total $ {orderInfo.total}
                                                </td>
                                              </tr>
                                            </>
                                          )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td className="content-block">
                              Invoice #{orderInfo.ourTransactionId}
                              <br />
                              Barefoot Beach House
                              <br />
                              332 S Gulfview Blvd
                              <br />
                              Clearwater Beach, FL 33767
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="footer">
                <table width="100%">
                  <tbody>
                    <tr>
                      <td className="text-center content-block">
                        Questions? Email{" "}
                        <a
                          href={`mailto:sales@shadepatrol.com?subject=question about my rental : ${orderInfo.ourTransactionId}`}
                        >
                          sales@shadepatrol.com
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

const mapStateToProps = (state) => ({
  orderInfo: state.pages.receiptData.orderInfo,
  orderInfoData: state.pages.receiptData.orderInfoData,
  receiptData: state.pages.receiptData,
});

export default connect(mapStateToProps, { getReceipt })(Receipt);
