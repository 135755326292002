import React, { createContext, useEffect, useMemo, useState } from "react";
import socketIOClient from "socket.io-client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import store from "./client/store";

// CSS
import "./assets/App.css";
import ScrollToTop from "./components/layout/ScrollToTop";
import { appRoutes } from "./App.Router";
import { toast } from "react-toastify";

export const SocketContext = createContext(null);

const newSocket = socketIOClient({
  transports: ["polling"],
});

const toastSettings = {
  position: "top-right",
  autoClose: false,
  hideProgressBar: true,
  closeOnClick: true,
  draggable: false,
  theme: "colored",
};

const App = () => {
  const [socket, setSocket] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const appVersion = localStorage.getItem("appVersion");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const authToken = localStorage.getItem("authToken");
  let location;

  useEffect(() => {
    newSocket.on("connect", () => {
      if (!userInfo) return;
      newSocket.emit("join", authToken);
    });

    newSocket.on("errorMessage", (msg) => {
      toast.error(msg, toastSettings);
    });

    newSocket.on("message", (msg) => {
      toast.success(msg, toastSettings);
    });

    newSocket.on("disconnect", (reason) => {
      console.log(`Client disconnected: ${reason}`);
    });

    newSocket.on("checkAuth", (data) => {
      location = window.location.href
        .replace(window.location.protocol + "//", "")
        .replace(window.location.host, "");

      // console.log("checkAuth", data, location);

      if (
        !data.authorized &&
        !location.includes("/auth/login") &&
        !location.includes("/receipt/")
      ) {
        localStorage.removeItem("token");
        localStorage.removeItem("userRole");
        localStorage.removeItem("userType");
        localStorage.removeItem("userInfo");
        clearInterval(interval);

        // redirect using react router dom to /login
        window.location.href = "/auth/login?AV=" + appVersion;
      }
    });

    const interval = setInterval(() => {
      location = window.location.href
        .replace(window.location.protocol + "//", "")
        .replace(window.location.host, "");

      const token = localStorage.getItem("token");

      if (
        !token &&
        !location.includes("/auth/login") &&
        !location.includes("/menu/") &&
        !location.includes("/receipt/")
      ) {
        window.location.href = "/auth/login?AV=" + appVersion;
      } else if (token) {
        newSocket.emit("checkAuth", token);
      }
    }, 3000);

    setSocket(newSocket);
  }, []);

  const socketContext = useMemo(
    () => ({
      socket,
      searchTerm,
      setSearchTerm,
    }),
    [socket, searchTerm, setSearchTerm],
  );

  return (
    <Provider store={store}>
      <SocketContext.Provider value={socketContext}>
        <RouterProvider router={appRoutes}>
          <ScrollToTop />
        </RouterProvider>
      </SocketContext.Provider>
    </Provider>
  );
};

export default App;
